<template>
  <div class="pd26 mt30 min-hg460 bg-white border-radius-10">
    <h3 class="flex flex-direction-row align-center justify-between home-title">
      <span>{{ atEngagementType }}</span>
    </h3>
    <div class="mt10">
      <div class="pt14 pb20 flex justify-between align-center">
        <div class="flex justify-center capsule-wrap">
          <div
            class="capsule-item"
            v-for="item in engagementTypes"
            :key="item"
            :class="{ 'capsule-active': item == atEngagementType }"
            @click="selectEngagementTypeHandle(item)"
          >
            {{ item }}
          </div>
        </div>
        <div class="wd360 flex-row jcsb">
          <!-- 年 -->
          <el-date-picker
            v-model="accordingYear"
            type="year"
            value-format="yyyy"
            size="small"
            :clearable="false"
            :append-to-body="false"
            class="wd120"
            @change="accordingYearChange"
          ></el-date-picker>
          <span class="fwb">{{ chartStatus }} {{ monthMap[chartMonth] ? `(${monthMap[chartMonth]})` : "" }}</span>
        </div>
      </div>
    </div>
    <div class="flex-row jcsb">
      <div class="hg350 chartWrap" id="engagement-trend-id"></div>
      <div class="wd360">
        <el-table border :data="companyList" height="316" :default-sort="{ prop: 'companyName', order: 'ascending' }">
          <el-table-column prop="companyName" label="Compay Name" align="center" sortable></el-table-column>
        </el-table>
        <el-button type="text" icon="el-icon-download" :disabled="!companyList.length" @click="handleExportExcel">Export Excel</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { open } from "@/utils";
import axiosInstance from "@/utils/http";
export default {
  data() {
    return {
      engagementTypes: ["AGM/EGM", "Report Filing", "CIT filing", "GST filing", "Other tax filing", "Secretary", "Account", "Advisory", "HR", "Others"],
      atEngagementType: "",
      accordingYear: "",
      companyList: [],
      monthMap: {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      },
      chartMonth: "",
      statusMap: {
        Completed: "COMPLETED",
        Uncompleted: "UNCOMPLETED",
        "N/A": "NONE",
      },
      chartStatus: "",
      myChart: null,
    };
  },
  methods: {
    // 导出 Excel
    async handleExportExcel() {
      open(
        `${axiosInstance.defaults.baseURL}/admin/engagementControl/download?controlType=${this.atEngagementType}&year=${this.accordingYear}&month=${
          this.chartMonth
        }&status=${this.statusMap[this.chartStatus] || ""}`,
      );
    },
    // 格式化数据
    formatData(dataList) {
      // 初始化月份数组
      const months = Array.from({ length: 12 }, (_, index) => ({
        month: (index + 1).toString().padStart(2, "0"),
        completedCnt: 0,
        uncompletedCnt: 0,
        noneCnt: 0,
      }));
      // 更新已有数据
      dataList.forEach(item => {
        const index = parseInt(item.month, 10) - 1;
        if (index >= 0 && index < months.length) {
          months[index].completedCnt = item.completedCnt;
          months[index].uncompletedCnt = item.uncompletedCnt;
          months[index].noneCnt = item.noneCnt;
        }
      });
      return months;
    },
    // 公司托管项年度趋势
    getEngagementControlTrend(type) {
      if (type) {
        this.atEngagementType = type;
      }
      this.$http
        .get("/admin/statistics/getEngagementControlTrend", {
          params: {
            controlType: this.atEngagementType,
            year: this.accordingYear,
          },
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            let xList = [];
            let completedCntList = [];
            let uncompletedCntList = [];
            let noneCntList = [];
            console.log(data);
            const dataList = data ? this.formatData(data) : [];
            dataList.forEach(item => {
              xList.push(item.month);
              completedCntList.push(item.completedCnt);
              uncompletedCntList.push(item.uncompletedCnt);
              noneCntList.push(item.noneCnt);
            });
            const dataItem = {
              xList,
              completedCntList,
              uncompletedCntList,
              noneCntList,
            };
            // 柱状图
            this.setTransactionAmount(dataItem);
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 公司托管项月度公司列表
    getEngagementTrendCompanyList() {
      this.$http
        .get("/admin/statistics/getEngagementTrendCompanyList", {
          params: {
            controlType: this.atEngagementType,
            year: this.accordingYear,
            month: this.chartMonth,
            status: this.statusMap[this.chartStatus],
          },
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.companyList = data || [];
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 柱状图
    setTransactionAmount(obj) {
      const option = {
        color: ["#67C23A", "#E6A23C", "#ff7e69"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: params => {
            // console.log(params);
            const monthMap = this.monthMap;
            let tooltipStr = "";
            if (params[0]) {
              const paramsOne = params[0];
              tooltipStr = `
                Month: ${monthMap[paramsOne.axisValue]} <br />
                ${paramsOne.seriesName}: ${paramsOne.value}
              `;
            }
            if (params[1]) {
              const paramsTwo = params[1];
              tooltipStr =
                tooltipStr +
                `
                <br />
                ${paramsTwo.seriesName}: ${paramsTwo.value}
              `;
            }
            if (params[2]) {
              const paramsThree = params[2];
              tooltipStr =
                tooltipStr +
                `
                <br />
                ${paramsThree.seriesName}: ${paramsThree.value}
              `;
            }
            return tooltipStr;
          },
        },
        legend: {
          data: ["Completed", "Uncompleted", "N/A"],
        },
        grid: {
          left: "5%",
          right: 0,
          top: 60,
          bottom: 20,
        },
        xAxis: {
          type: "category",
          data: obj.xList,
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "Completed",
            data: obj.completedCntList,
            type: "bar",
            label: {
              show: true,
              position: "top",
              fontSize: 14,
            },
          },
          {
            name: "Uncompleted",
            data: obj.uncompletedCntList,
            type: "bar",
            label: {
              show: true,
              position: "top",
              fontSize: 14,
            },
          },
          {
            name: "N/A",
            data: obj.noneCntList,
            type: "bar",
            label: {
              show: true,
              position: "top",
              fontSize: 14,
            },
          },
        ],
      };
      this.myChart?.setOption(option);
    },
    selectEngagementTypeHandle(type) {
      // 重置公司列表
      this.initCompanyList();
      this.getEngagementControlTrend(type);
    },
    // 选择年 - 日历
    accordingYearChange() {
      // 重置公司列表
      this.initCompanyList();
      this.getEngagementControlTrend();
    },
    // 重置公司列表
    initCompanyList() {
      this.chartMonth = "";
      this.chartStatus = "";
      this.companyList = [];
    },
  },
  activated() {
    // 初始化参数
    this.atEngagementType = this.engagementTypes[0];
    this.accordingYear = this.$moment().year()?.toString();
    this.initCompanyList();
    this.getEngagementControlTrend();
  },
  mounted() {
    // 初始化echarts
    if (document.getElementById("engagement-trend-id")) {
      this.myChart = this.$echarts.init(document.getElementById("engagement-trend-id"));
      this.myChart?.on("click", params => {
        // 确保点击的是柱状图的柱子
        if (params.componentType === "series" && params.seriesType === "bar") {
          console.log("柱状图被点击了，数据是：", params);
          this.chartMonth = params.name;
          this.chartStatus = params.seriesName;
          this.getEngagementTrendCompanyList();
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.min-hg460 {
  min-height: 460px;
}

.capsule-wrap {
  border-radius: 26px;
  overflow: hidden;

  .capsule-item {
    padding: 0 10px;
    text-align: center;
    height: 30px;
    line-height: 28px;
    background-color: #ebeefd;
    color: #7987a0;
    font-size: 12px;
    cursor: pointer;
  }

  .capsule-active {
    background-color: #0072ff !important;
    color: #fff !important;
  }
}

.aurantia {
  color: #ff7e69 !important;
}

.cyan {
  color: #52d4ff !important;
}

.home-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  color: $form-item-font-color;
}

.home-subhead {
  margin: 0;
  padding: 0;
  margin-top: 6px;
  font-size: 14px;
  font-weight: bold;
  color: $form-item-font-color;
  line-height: 20px;
}

.symbol-price {
  font-size: 14px;
  font-weight: bold;
  color: $--color-primary;
  line-height: 1em;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  word-break: break-all;
  width: 50%;

  .total-price {
    font-size: 20px;
    font-weight: bold;
    line-height: 1em;
  }
}

.chartWrap {
  width: calc(100% - 360px);
  padding-right: 60px;
}
</style>
