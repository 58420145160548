<template>
  <div class="login-body fxRow">
    <div class="firm-bg-img fxRow">
      <img src="@/assets/img/logo.png" class="logo" />
      <img src="@/assets/img/firm.png" />
    </div>
    <div class="ms-login fxRow">
      <div class="from-wrap">
        <div class="fxRow brand-name">Company Management</div>
        <div class="fxRow">
          <el-button class="login-btn" type="primary" @click="gotoLoginPage()">Sign in</el-button>
        </div>
      </div>
    </div>
    <div class="footBox">
      <p class="fs12" style="font-family: '微软雅黑'">
        Copyright© 2021 星中(广州)商务咨询有限公司
        <a href="https://beian.miit.gov.cn/">粤ICP备2021165700号</a>
      </p>
    </div>
  </div>
</template>

<script>
import { clearLoginInfo, saveLoginInfo } from "@/utils/auth";
import { open } from "@/utils";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      timer: null,
    };
  },
  created() {
    if ("logout" in this.$route.query) {
      clearLoginInfo();
    }
    const code = this.$route.query.code;
    if (code) {
      this.doLogin(code);
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getRealLoginUrl() {
      let apiBaseUrl = this.$http.defaults.baseURL;
      if (apiBaseUrl.endsWith("/")) {
        apiBaseUrl = apiBaseUrl.slice(0, -1);
      }
      const jumpUri = document.location.origin + "/api/callback";
      const oauth2AuthorizationCodeUrl = apiBaseUrl + `/oauth2/authorization/company?redirect_uri=${encodeURIComponent(jumpUri)}`;
      return { oauth2AuthorizationCodeUrl, jumpUri };
    },
    async gotoLoginPage() {
      const realUrlData = this.getRealLoginUrl();
      open(realUrlData.oauth2AuthorizationCodeUrl);
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        console.log("check code");
        const code = Cookies.get("code");
        if (code) {
          clearInterval(this.timer);
          this.doLogin(code, realUrlData.jumpUri);
        }
      }, 1000);
    },
    async doLogin(code, jumpUri) {
      clearLoginInfo();
      try {
        await this.login({ code, jumpUri });
        Cookies.remove("code");
        this.$router.replace("/");
      } catch (error) {
        console.log(error);
        this.$message(error.msg || "failed to login");
        this.$router.replace("/login");
      }
    },
    async login(payload) {
      const { code, jumpUri } = payload;
      const resData = await this.$http.postForm("/code2token", {
        code: code,
        redirectUri: jumpUri,
      });
      console.log(resData);
      if (resData.token) {
        saveLoginInfo(resData.token);
      } else {
        // this.$message(resData.msg || "failed to login");
        const message = resData.msg || "failed to login";
        this.$confirm(`${message}, Log out of the account?`, "Warning", {
          confirmButtonText: "Log out",
          cancelButtonText: "Cancel",
          type: "warning",
        })
          .then(() => {
            // 方式1: 跳转到登出页退出后，再跳转到登录页进行登录，登录成功或失败后再回跳到当前网址
            // const realUrlData = this.getRealLoginUrl();
            // const linkUrl = document.location.origin + realUrlData.oauth2AuthorizationCodeUrl;
            // const loginAgainUrl = process.env.VUE_APP_SSO_URL + `/logout?continue=${encodeURIComponent(linkUrl)}`;
            // console.log(loginAgainUrl);
            // console.log(realUrlData);
            // open(loginAgainUrl);
            // if (this.timer) {
            //   clearInterval(this.timer);
            // }
            // this.timer = setInterval(() => {
            //   console.log("check code");
            //   const code = Cookies.get("code");
            //   if (code) {
            //     clearInterval(this.timer);
            //     this.doLogin(code, realUrlData.jumpUri);
            //   }
            // }, 1000);

            // 方式2: 跳转到登出页退出后，回跳到当前网址
            const currentUrl = document.location.origin;
            const quitUrl = process.env.VUE_APP_SSO_URL + `/logout?continue=${encodeURIComponent(currentUrl)}`;
            window.location.replace(quitUrl);
          })
          .catch(action => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-body {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  min-height: 800px;
  background: #f1f5fc;
  position: relative;
  .firm-bg-img {
    width: 60%;
    height: 100%;
    background: #f1f5fc;
    position: relative;
    .logo {
      position: absolute;
      top: 26px;
      left: 30px;
      z-index: 100;
      height: 50px;
    }
  }
  .ms-login {
    width: 40%;
    height: 100%;
    background: #fafcff;
    box-shadow: 0 21px 40px 0 rgba(208, 229, 255, 0.26);
  }
  .footBox {
    position: absolute;
    bottom: 14px;
    left: 0;
    width: 60%;
    text-align: center;
    line-height: 0;
    color: #667288;
    z-index: 10000;
  }
  .brand-name {
    font-size: 28px;
    font-weight: bold;
    // border: 1px solid #ccc;
    padding: 10px 5px;
  }
  .login-btn {
    width: 240px;
    height: 50px;
    margin: 50px;
    font-size: 18px;
  }
}
</style>
