<template>
  <div class="home-wrap">
    <el-row :gutter="30">
      <el-col :span="12">
        <!-- Total Transaction / Total Turnover -->
        <total-transaction></total-transaction>
        <!-- Total Unpaid / Total Turnover -->
        <total-unpaid></total-unpaid>
        <!-- Processing Time -->
        <processing-time></processing-time>
        <!-- Checker -->
        <checker></checker>
        <!-- Reviewer -->
        <reviewer></reviewer>
      </el-col>
      <el-col :span="12">
        <!-- 饼图 Accepted Client / Pending Modified Client / Potential Client / Lost Client -->
        <accepted-client-pie></accepted-client-pie>
        <!-- 折线图 Accepted Client / Pending Modified Client / Potential Client / Lost Client -->
        <accepted-client-line></accepted-client-line>
        <!-- Statistics of Transaction Status at Each Stage -->
        <statistics-transaction-status />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <engagement-trend></engagement-trend>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TotalTransaction from "./modules/TotalTransaction";
import TotalUnpaid from "./modules/TotalUnpaid";
import ProcessingTime from "./modules/ProcessingTime";
import Checker from "./modules/Checker";
import Reviewer from "./modules/Reviewer";
import AcceptedClientPie from "./modules/AcceptedClientPie";
import AcceptedClientLine from "./modules/AcceptedClientLine";
import StatisticsTransactionStatus from "./modules/StatisticsTransactionStatus";
import EngagementTrend from "./modules/EngagementTrend";
export default {
  data() {
    return {};
  },
  components: {
    TotalTransaction,
    TotalUnpaid,
    ProcessingTime,
    Checker,
    Reviewer,
    AcceptedClientPie,
    AcceptedClientLine,
    StatisticsTransactionStatus,
    EngagementTrend,
  },
  activated() {
    // echarts 跟随页面变化
    setTimeout(() => {
      window.onresize = () => {
        if (document.getElementById("clientChunk")) {
          this.$echarts.init(document.getElementById("clientChunk")).resize();
        }
        if (document.getElementById("client-quantity-line")) {
          this.$echarts.init(document.getElementById("client-quantity-line")).resize();
        }
        if (document.getElementById("engagement-trend-id")) {
          this.$echarts.init(document.getElementById("engagement-trend-id")).resize();
        }
        // 敏感数据 - 财务权限
        if (this.isAuth("finance:financial")) {
          if (document.getElementById("transaction-amount")) {
            this.$echarts.init(document.getElementById("transaction-amount")).resize();
          }
          if (document.getElementById("unpaid-amount")) {
            this.$echarts.init(document.getElementById("unpaid-amount")).resize();
          }
        }
      };
    }, 200);
  },
};
</script>

<style lang="scss" scoped></style>
