var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home-wrap"
  }, [_c('el-row', {
    attrs: {
      "gutter": 30
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('total-transaction'), _c('total-unpaid'), _c('processing-time'), _c('checker'), _c('reviewer')], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('accepted-client-pie'), _c('accepted-client-line'), _c('statistics-transaction-status')], 1)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('engagement-trend')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }