<template>
  <div class="flex align-center justify-center login-wrap">
    <div class="flex align-center justify-center firm-bg-img">
      <img src="@/assets/img/logo.png" alt="" class="logo" />
      <img src="@/assets/img/firm.png" alt="" />
    </div>
    <div class="flex align-center justify-center ms-login">
      <div class="from-wrap">
        <div class="ms-title">
          <span class="black45 fwb">EStar</span>
        </div>
        <p class="sendEmailTipe">Please enter your login email address and we will send you a link to reset your password</p>
        <el-form :model="accountForm" :rules="accountRule" ref="accountFormRef" @submit.native="accountFormSubmit" hide-required-asterisk>
          <el-form-item prop="email">
            <el-input type="text" v-model="accountForm.email" placeholder="Email" />
          </el-form-item>
          <!-- 发送结果提示 -->
          <div class="sendRes">
            <p class="green" v-if="sendEmailType == 1">
              <i class="el-icon-success"></i>
              Message sent successfully
            </p>
            <p class="red" v-if="sendEmailType == 0">
              <i class="el-icon-error"></i>
              Message sending failed
            </p>
          </div>
          <el-button :disabled="accountBtnDisabled" @click="accountFormSubmit" class="mt12 login-btn" native-type="submit" type="primary">
            Confirm
          </el-button>
        </el-form>
        <div class="pt6">
          <p class="loginTips">
            Already have an account?
            <router-link to="/login">Sign in</router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="footBox">
      <p class="fs12">
        Copyright© 2021 星中(广州)商务咨询有限公司
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021165700号</a>
      </p>
    </div>
  </div>
</template>
<script>
import { checkIsLogin, saveLoginInfo } from "@/utils/auth";
import { isEmail } from "@/utils/validate";
export default {
  data() {
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error("Please provide a valid email address"));
      } else {
        callback();
      }
    };
    return {
      accountForm: {
        email: "",
      },
      accountRule: {
        email: [{ validator: validateEmail, trigger: "blur" }],
      },
      accountBtnDisabled: false,
      sendEmailType: 2,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (checkIsLogin()) {
      next(vm => {
        vm.$router.push({ name: "home" });
      });
    } else {
      next();
    }
  },
  methods: {
    accountFormSubmit(e) {
      e.preventDefault();
      this.$refs["accountFormRef"].validate(async valid => {
        if (valid) {
          this.accountBtnDisabled = true;
          this.sendEmailType = 2;
          let { code, msg } = await this.$http.post("/resetSysPwdByEmail", { email: this.accountForm.email });
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1000,
              onClose: () => {
                this.sendEmailType = 1;
                this.accountBtnDisabled = false;
              },
            });
          } else {
            this.$message.error(msg);
            this.sendEmailType = 0;
            this.accountBtnDisabled = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.login-wrap {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  min-height: 800px;
  background: #f1f5fc;
  position: relative;

  .firm-bg-img {
    width: 60%;
    height: 100%;
    background: #f1f5fc;
    position: relative;

    .logo {
      position: absolute;
      top: 26px;
      left: 30px;
      z-index: 100;
      height: 50px;
    }
  }

  .ms-login {
    width: 40%;
    height: 100%;
    background: #fafcff;
    box-shadow: 0px 21px 40px 0px rgba(208, 229, 255, 0.26);
    .from-wrap {
      width: 430px;
      margin: -60px auto 0px;
      .ms-title {
        width: 100%;
        text-align: center;
        padding-bottom: 60px;
        font-size: 22px;
      }

      .sendEmailTipe {
        color: #667288;
        line-height: 18px;
      }

      .sendRes {
        p {
          margin: 0;
          padding: 0;
        }
        .green {
          color: $--color-success;
        }
        .red {
          color: $--color-danger;
        }
      }
    }
    ::v-deep .el-input__inner {
      background: #ffffff;
      border-color: #e1ecff;
      border-radius: 6px;
      height: 50px;
      line-height: 50px;
    }
  }

  .login-btn {
    text-align: center;
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
  }

  .loginTips {
    color: #667288;
    text-align: right;
  }

  .footBox {
    position: absolute;
    bottom: 14px;
    left: 0;
    width: 60%;
    text-align: center;
    line-height: 0em;
    color: #667288;
    z-index: 10000;
    a {
      color: #667288;
    }
  }
}
</style>
